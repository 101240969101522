<template>
  <div class="row" v-if="Object.keys(purchase_request).length !== 0">
    <div class="col-md-12 mb-3" v-if="user.role.includes('head_of_admin')">
      <a-button v-if="purchase_request.purchase_request_status.includes('ADMIN_PENDING') && !purchase_request.purchase_request_status.includes('ADMIN_APPROVED')" :loading="btnAdminApprove" class="btn btn-success btn-sm mr-2 float-right" @click.prevent="adminApproveStatus(purchase_request.id)"><i class="fa fa-check"></i> Approve</a-button>
    </div>
    <purchase-request-header-status :current="current" :purchase_request="purchase_request"></purchase-request-header-status>
    <div class="col-md-12">
      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="col-sm-6">
              <div class="form-group">
                <label class="font-weight-bold">PR Number</label>
                <p>{{ purchase_request.pr_number }}</p>
              </div>
            </div>
            <div class="col-sm-6">
              <div class="form-group">
                <label class="font-weight-bold">Delivery On Before</label>
                <p>{{ purchase_request.delivery_date }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card">
        <div class="card-body">
          <form action="">
            <table class="table table-hover table-bordered text-center">
              <thead>
                <tr>
                  <th :class="{change_color: scrollPosition > 150}">Name</th>
                  <th :class="{change_color: scrollPosition > 150}">Unit</th>
                  <th :class="{change_color: scrollPosition > 150}">Warehouse Current Stock</th>
                  <th :class="{change_color: scrollPosition > 150}">Requested Qty</th>
                  <th :class="{change_color: scrollPosition > 150}">Unit Price</th>
                  <th :class="{change_color: scrollPosition > 150}">Sub Total</th>
                  <th :class="{change_color: scrollPosition > 150}">Total</th>
                  <th :class="{change_color: scrollPosition > 150}">Remarks</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(product, index) in products" :key="index">
                  <td>{{ product.product.name }}</td>
                  <td>{{ product.product.unit }}</td>
                  <td>{{ product.product.available_quantity }}</td>
                  <td>{{ product.quantity }}</td>
                  <td>{{ product.unit_price }}</td>
                  <td>{{ currencyConverter(product.sub_total) }}</td>
                  <td>{{ currencyConverter(product.total) }}</td>
                  <td>{{ product.remarks }}</td>
                </tr>
                <!--  Total, Others, shipping-->
                <tr>
                  <th style="text-align: right" colspan="6">SUBTOTAL</th>
                  <td>{{ currencyConverter(purchase_request.subtotal) }}</td>
                </tr>
                <tr>
                  <th style="text-align: right" colspan="6">OTHER</th>
                  <td>{{ currencyConverter(purchase_request.others) }}</td>
                </tr>
                <tr>
                  <th style="text-align: right" colspan="6">TOTAL</th>
                  <td>{{ currencyConverter(purchase_request.grand_total) }}</td>
                </tr>
              </tbody>
            </table>
            <a-skeleton active :loading="loading"></a-skeleton>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import apiClient from '@/services/axios'
import { mapGetters } from 'vuex'
import purchaseRequestHeaderStatus from '@/views/purchaseRequest/partial/purchase_request_header_status'

export default {
  name: 'purchaseRequestDetails',
  components: { purchaseRequestHeaderStatus },
  data() {
    return {
      products: [],
      purchase_request: {},
      loader: false,
      flag: false,
      loading: false,
      mailLoader: false,
      btnAdminApprove: false,
      btnAdminReject: false,
      current: 0,
      scrollPosition: null,
    }
  },
  computed: {
    ...mapGetters('user', ['user']),
  },
  mounted() {
    this.getPurchaseRequest()
    window.addEventListener('scroll', this.updateScroll)
  },
  methods: {
    getPurchaseRequest() {
      this.loading = true
      const purchaseRequestId = this.$route.params.purchase_request_id
      apiClient.get('api/purchase-requests/' + purchaseRequestId)
        .then(response => {
          if (!response.data.error) {
            this.loading = false
            const data = response.data
            this.products = data.products
            this.purchase_request = data.purchase_request
            this.current = data.max_purchase_request_status
          } else {
            this.loading = false
            this.$notification.error({
              message: response.data.message,
            })
          }
        })
        .catch(error => {
          console.log(error)
        })
    },
    adminApproveStatus(purchaseRequestId) {
      if (confirm('Do you really want to approve?')) {
        this.btnAdminApprove = true
        apiClient.get('api/admin/purchase-request/status/approve/' + purchaseRequestId).then(response => {
          this.btnAdminApprove = false
          if (!response.error) {
            if (response.data.error === true) {
              this.$notification.error({
                message: response.data.message,
              })
            } else {
              this.$notification.success({
                message: response.data.message,
              })
              this.getPurchaseRequest()
            }
          } else {
            this.$notification.error({
              message: 'Approved Failed',
            })
          }
        }).catch(error => {
          this.btnAdminApprove = false
          console.log(error)
        })
      }
    },
    adminRejectStatus(purchaseRequestId) {
      if (confirm('Do you really want to reject?')) {
        this.btnAdminReject = true
        apiClient.get('api/admin/purchase-request/status/reject/' + purchaseRequestId).then(response => {
          this.btnAdminReject = false
          if (!response.error) {
            if (response.data.error === true) {
              this.$notification.error({
                message: response.data.message,
              })
            } else {
              this.$notification.success({
                message: response.data.message,
              })
              this.getPurchaseRequest()
            }
          } else {
            this.$notification.error({
              message: 'Rejected Failed',
            })
          }
        }).catch(error => {
          console.log(error)
        })
      }
    },
    currencyConverter(x) {
      return this.$localCurrency(x)
    },
    updateScroll() {
      this.scrollPosition = window.scrollY
    },
  },
}
</script>
<style scoped>
table {
  position: relative;
}

th {
  position: sticky;
  top: 0;
  background: #ffffff;
  z-index: 1;
}
.change_color {
  background-color: #4B7CF3;
  color: #ffffff;
}
</style>
